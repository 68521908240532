



























import Vue from "vue";
import {VERSION} from "@/config/version";
export default Vue.extend({
  name: "About",
  data() {
    return {
      patients: new Array(),
      version: VERSION,
      konamiCode: "",
      zeldaMode: false,
    };
  },
  methods: {
    keyupHandler(e: any) {
      const action = e.code.replace("Arrow", "").toLowerCase();
      this.konamiCode += action;
      if (this.konamiCode.includes("upupdowndownleftleftrightrightkeybkeyq")) {
        this.zeldaMode = true;
        // document.getElementById("audio-player").play();
      }
    },
    killzeldaMode() {
      this.zeldaMode = false;
      this.konamiCode = "";
    },
    hotReload() {
      const d = new Date().getTime();
      this.$router.push({name: "home", query: {cache: d?.toString()}});
    },
  },
  mounted() {
    document.addEventListener("keyup", this.keyupHandler);
  },
  destroyed() {
    document.removeEventListener("keyup", this.keyupHandler);
  },
});
